import styled from "styled-components";
import { workExperiences } from "./data";

const Emoji = ({ label, symbol, size }) => (
  <span
    role="img"
    // aria-label={label ? label : ""}
    // aria-hidden={label ? "false" : "true"}
    aria-hidden="true"
    style={{ fontSize: size }}
  >
    {symbol}
  </span>
);

const Work = () => {
  return (
    <>
      <SectionDiv>
        {/* <SectionTitle>work</SectionTitle> */}
        {workExperiences.map((experience, i) => (
          <>
            <WorkEntry key={i}>
              <Logo src={experience.organizationLogo} alt={experience.imgAlt} />
              <WorkTextDiv>
                <Organization aria-label="organisation">
                  {experience.organization}
                </Organization>
                <Position aria-label="work title">
                  {experience.position}
                </Position>
                <Location aria-label="city">
                  <Emoji
                    label="pushpin"
                    symbol="📍"
                    size="1em"
                    aria-hidden="true"
                  />
                  {experience.location}
                </Location>
                <ResponsibilitiesList aria-label="tasks">
                  {experience.responsibilities.map((responsibility, j) => (
                    <li key={j}>{responsibility}</li>
                  ))}
                </ResponsibilitiesList>
              </WorkTextDiv>
            </WorkEntry>
            {/* <Separator /> */}
          </>
        ))}
      </SectionDiv>
    </>
  );
};

const SectionDiv = styled.div`
  border-radius: 40px;
  /* background: rgba(217, 217, 217, 0.5); */
  /* background: rgba(0, 0, 0, 0.7); */
  background: rgba(217, 217, 217, 0.07);
  padding: 40px;
  margin: auto;
  /* width: 100%; */
  max-width: 1000px;
`;

const SectionTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  margin-top: 0;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;

  @media only screen and (max-width: 480px) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 4vh;

    /* border: 1px solid blue; */
  }
`;

const WorkEntry = styled.div`
  display: flex;
  /* gap: 40px; */
  margin: 6vh auto;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Position = styled.h4`
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  @media only screen and (max-width: 480px) {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 2px;
  }
`;

const Organization = styled.p`
  font-size: 18px;
  margin: 0 0 1vh 0;
  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const Location = styled.p`
  font-size: 14px;
  margin: 2vh 0 0 0;
  @media only screen and (max-width: 480px) {
    font-size: 11px;
  }
`;

const WorkTextDiv = styled.div`
  margin: auto 0 auto 6vh;
  /* border: pink solid 2px; */
  width: 70vw;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;

    margin: 0;
  }
`;

const Separator = styled.div`
  height: 0.5px;
  margin-top: 4vh;
  margin-bottom: 4vh;
  background: linear-gradient(
    to right,
    #b63f95 9.6%,
    #ec6a42,
    #f2b858,
    #92f8dd 93.6%
  );
`;

const ResponsibilitiesList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  line-height: 35px;

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    text-align: left;
    line-height: 20px;
  }
`;

export default Work;
