import React, { useState, useEffect } from "react";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import ProjectDetails from "./ProjectDetails";
import Contact from "./Contact";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import Menu from "./Menu";
import styled from "styled-components";
import logoproto from "./assets/images/vscodesportologowhite.png";

const menuSize = 50;

const App = () => {
  const { projectId } = useParams();
  const isHome = projectId === undefined; // Check if on the home route

  return (
    <Router>
      <AppWrapper>
        <Header>
          <Menu size={menuSize} />
          <Logo href="/">
            <LogoIcon src={logoproto} /> vscodes
          </Logo>
        </Header>

        <Routes>
          <Route path="/" element={<Home />} role="Home" />
          <Route path="/about" element={<About />} role="About" />
          <Route path="/work" element={<Projects />} role="Work" />
          <Route
            path="/work/:projectId"
            element={<ProjectDetails />}
            role="Work-details"
          />
          <Route path="/contact" element={<Contact />} role="Contact" />
          {/*<Route path="*" element={<Error />} />  */}
        </Routes>
      </AppWrapper>
    </Router>
  );
};

const AppWrapper = styled.div`
  /* z-index: 19;
  background-color: green;
  margin: auto; */
`;

const Header = styled.header`
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  z-index: 20;
  /* background-color: blue; */

  @media only screen and (max-width: 767px) {
    align-items: center;
    /* background-color: pink; */
    /* width: 50vw; */
    display: flex;
    /* justify-content: center; */
  }
`;

const Logo = styled.a`
  // Re-using the menu size as left offset will keep them in sync.
  // We also add an extra 8px offset to space them out
  margin-left: ${menuSize + 8}px;
  color: white;
  font-family: Italiana;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.05);

  /* background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%); */

  &:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 1272px) {
    font-size: 18px;
    margin-left: 0;
    align-items: center;
  }

  @media only screen and (max-width: 780px) {
    margin-left: ${menuSize + 50}px; // Center the logo on mobile
  }

  /* @media screen and (max-width: 480px) {
    font-size: 20px;
    margin: 0; // Center the logo on mobile
    left: 0;
  } */
`;

const LogoIcon = styled.img`
  width: 30px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
`;

export default App;
