import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import menuIcon from "./assets/images/menuIcon.png";
import closeIcon from "./assets/images/closeIcon.png";
import { FiHome, FiBell, FiBookmark } from "react-icons/fi";
import { FaCat } from "react-icons/fa";
import LogoHome from "./assets/images/homeIcon.png";

const Menu = ({ size }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuIconRef = useRef(null);
  const divNavRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const closeMenu = () => {
    setShowMenu(false);
    menuIconRef.current.focus();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleMenu();
    }
  };

  useEffect(() => {
    const divNavSections = divNavRef.current;
    if (!showMenu) {
      menuIconRef.current.blur();
      divNavSections.setAttribute("inert", "");
    } else {
      divNavSections.removeAttribute("inert");
    }
  }, [showMenu]);

  return (
    <>
      <MenuDiv size={size}>
        <DivNavSections
          showMenu={showMenu}
          ref={divNavRef}
          // aria-hidden={showMenu}
          // inert={showMenu ? true : false}
        >
          <AnchorLink href="/" onClick={closeMenu}>
            home
          </AnchorLink>
          <AnchorLink href="/about" onClick={closeMenu}>
            about
          </AnchorLink>

          <AnchorLink href="/work" onClick={closeMenu}>
            work
          </AnchorLink>

          <AnchorLink href="/contact" onClick={closeMenu}>
            contact
          </AnchorLink>
        </DivNavSections>
        <MenuIcon
          src={showMenu ? closeIcon : menuIcon}
          onClick={toggleMenu}
          onKeyPress={handleKeyPress}
          alt="Open menu"
          tabIndex="0"
          ref={menuIconRef}
          role="navigation"
          aria-label={showMenu ? "Close Menu" : "Main menu"}
        />
      </MenuDiv>
    </>
  );
};

const MenuDiv = styled.div`
  position: fixed;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const MenuIcon = styled.img`
  cursor: pointer;
  max-width: 100%;
  position: relative;

  @media only screen and (max-width: 1000px) {
    top: 88vh;
  }
  @media only screen and (max-width: 780px) {
    top: 87dvh;
    left: 42vh;
    width: 55px;
    height: 55px;
  }
`;

const DivNavSections = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ showMenu }) => (showMenu ? "100vw" : "0")};
  height: ${({ showMenu }) => (showMenu ? "100vh" : "0")};
  overflow: hidden;
  transition: width 0.5s, height 0.5s;
  background-image: url("https://images.unsplash.com/photo-1579547944212-c4f4961a8dd8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3039&q=80");
  background-size: cover;
  align-items: center;

  @media only screen and (max-width: 480px) {
    width: ${({ showMenu }) => (showMenu ? "100vw" : "0")};
    height: ${({ showMenu }) => (showMenu ? "100vh" : "0")};
  }
`;

const AnchorLink = styled.a`
  font-size: 50px;
  text-decoration: none;
  padding: 40px;
  border-radius: 30px;
  display: flex;
  font-weight: 400;
  margin: 0 auto 0 auto;
  width: 100vw;
  justify-content: center;

  &:hover {
    text-decoration: none;
    background: rgba(217, 217, 217, 0.07);
    color: #fffc00;
  }

  &.active {
    color: #fffc00;
  }
`;

const IconHome = styled(FiHome)`
  /* margin-right: 20px; */
  /* background-color: white; */
  /* position: absolute; */
  font-size: 20px;
  font-weight: 400;
  top: 6vh;
  animation: none;
  bottom: none;
  left: 96vw;
  /* background: rgba(28, 30, 32, 0.5); */
  /* padding: 20px; */
  z-index: 19;
  /* background-color: pink; */

  @media only screen and (max-width: 480px) {
    left: 94vw;
  }
`;

const IconNotifications = styled(FiBell)`
  margin-right: 20px;
  background-color: transparent;
`;

export default Menu;
