const tech_skills = [
  {
    imgSrc: require("./assets/images/react.png"),
    skillName: "React",
  },
  {
    imgSrc: require("./assets/images/nodejs.png"),
    skillName: "Node.js",
  },
  {
    imgSrc: require("./assets/images/javascript.png"),
    skillName: "Javascript",
  },
  {
    imgSrc: require("./assets/images/html.png"),
    skillName: "HTML",
  },
  {
    imgSrc: require("./assets/images/css.png"),
    skillName: "CSS",
  },
  {
    imgSrc: require("./assets/images/mongo-db.png"),
    skillName: "MongoDB",
  },
  {
    imgSrc: require("./assets/images/analytics.png"),
    skillName: "Google Analytics",
  },
  {
    imgSrc: require("./assets/images/figma.png"),
    skillName: "Figma",
  },
  {
    imgSrc: require("./assets/images/meta.png"),
    skillName: "Meta Business",
  },
  // {
  //   imgSrc: require(""),
  //   skillName: "LinkedIn® Business Manager",
  // },
  {
    imgSrc: require("./assets/images/adobe-premiere.png"),
    skillName: "Premiere",
  },
  {
    imgSrc: require("./assets/images/adobe-photoshop.png"),
    skillName: "Photoshop",
  },
  {
    imgSrc: require("./assets/images/adobe-lightroom.png"),
    skillName: "Lightroom",
  },
  // {
  //   imgSrc: require("./assets/images/adobe-indesign.png"),
  //   skillName: "Indesign",
  // },
  // {
  //   imgSrc: require(""),
  //   skillName: "Branding",
  // },
  // {
  //   imgSrc: require(""),
  //   skillName: "Public Relations",
  // },
];

const workExperiences = [
  {
    organizationLogo: require("./assets/images/LogoFreelance.PNG"),
    imgAlt: "Emoji of a Black Woman coding",
    position: "Digital consultant",
    organization: "Freelance",
    location: "Canada",
    responsibilities: [
      "Full-Stack Web development",
      "Marketing development strategy",
      "Inclusive leadership",
      "Familiarization with various digital platforms",
      "Press relations and writing of press releases and/or press kits",
      "Digital community management",
      "Image management",
      "Coordination of multiplatform advertising campaigns",
    ],
  },
  {
    organizationLogo: require("./assets/images/LogoCMTL.jpeg"),
    imgAlt: "Concertation Montreal Logo",
    position: "Project & Communication Manager",
    organization: "Concertation Montréal (CMTL)",
    location: "Montreal",
    responsibilities: [
      "Develop and coordinate the implementation of communication plans for inclusive governance initiatives",
      "Coordinate the development and dissemination of communication tools for candidates and organizational leaders",
      "Promote CMTL's Diversity Applicant Database and encourage talent to apply",
      "Develop, coordinate and facilitate the programming of the Groupe des Trente initiative; an inclusive leadership program whose mission is to inspire a diverse succession in governance to enrich decision-making. Supported by the Ministère de l'Immigration, de la Francisation et de l'Intégration du Québec, the initiative brings together multidisciplinary experts from under-represented groups who are involved in boards of directors.",
    ],
  },
  {
    organizationLogo: require("./assets/images/LogoRepaf.jpeg"),
    imgAlt: "African Business Network Logo",
    position: "Communication Director",
    organization: "African Business Network (REPAF)",
    location: "Montreal",
    responsibilities: [
      "Set the strategic direction of the company's communication in terms of image, visibility, and message and define the annual communication plan",
      "Advise the President on the evolution of the brand identity and positioning internally and externally",
      "Oversee the creation of materials for the company's various internal and external audiences",
      "Lead and manage the communications team.",
    ],
  },
  {
    organizationLogo: require("./assets/images/LogoCCFC.jpeg"),
    imgAlt: "Chamber of Commerce France-Canada Logo",
    position: "Communications Officer, Social Networks and Web",
    organization: "Chamber of Commerce France-Canada",
    location: "Paris",
    responsibilities: [
      "Support the general manager in the realization of communication tasks",
      "Updating the website",
      "Animate and energize the company's social networks",
      "Contribute to the organization of conferences with Benjamin Smith, Jean Charest and Peter Todd, held by the organization at the Canadian Embassy in Paris",
      "Ensure a media watch on Franco-Canadian economic policies and news.",
    ],
  },
];

const academic_experiences = [
  {
    logo: require("./assets/images/LogoConcordia.png"),
    logoAlt: "Concordia University Logo",
    degree: "Diploma in Full-Stack Web Development",
    institution: "Concordia University",
  },
  {
    logo: require("./assets/images/LogoGI.png"),
    logoAlt: "Georgia Institute of Technology Logo",
    degree: "Course: Introduction to User Experience Design",
    institution: "Georgia Institute of Technology",
  },
  {
    logo: require("./assets/images/LogoFreeCodeCamp.jpeg"),
    logoAlt: "FreeCodeCamp Logo",
    degree: "Responsive Web Design Certification",
    institution: "freeCodeCamp",
  },
  {
    logo: require("./assets/images/LogoUqam.jpeg"),
    logoAlt: "University of Quebec in Montreal Logo",
    degree: "Bachelor in Digital Media",
    institution: "University of Quebec in Montreal",
    // description:
    //   "This bachelor's degree provides training in the understanding and analysis of digital media, taking into account their social, political, economic and technological dimensions, and their various fields and contexts of application. It trains strategic players capable of accompanying the communicative, political and social deployment of digital practices and technologies, thanks to a general knowledge of digital media in their theoretical and practical dimensions. It enables the acquisition and development of a critical posture that bridges the gap between techniques and their actual applications. The program offers students the opportunity to specialize, with honors, in one of three areas: artificial intelligence, digital media and video games. Graduates are thus able to ensure that communication and social issues are taken into account in the effective deployment of new technological devices: the impact of algorithms and artificial intelligence on living together, privacy and the commercial circulation of personal data, fake news, ethics, gamification and social issues (inclusion, gender representation, social themes, socio-educational vocations).",
  },
  {
    logo: require("./assets/images/LogoBrebeuf.png"),
    logoAlt: "Jean-De-Brebeuf College Logo",
    degree: "DEC in Art, Letters & Communication - Multimedia Creations",
    institution: "Jean-De-Brébeuf College",
  },
];

const codingProjects = [
  {
    id: "BingeBuddy",
    name: "BingeBuddy",
    className: "java",
    description:
      "is an intuitive platform that leverages the power of the Movies Database API, enabling two users to embark on a playful journey while deciding on their next entertainment choice.",
    sub: "A playful and collaborative movie and TV show selector",
    technologies: ["React", "Node.js", "MongoDB", "Auth0", "JSX", "HTML/CSS"],
    contributions: ["Conceptualization", "Front-end", "Back-end", "UI", "UX"],
    preview: require("./assets/images/BingeBuddyPreview.png"),
    previewAlt:
      "A representation of the application with a colorful background and the title BingeBuddy in big white letters. At the top left of the image appears the logo which is represented by the letters BB. At the top right appears an Login button. At the bottom center appears the slogan of the platform : We find the perfect movie/show compromise faster than you can say popcorn.",
    // github_link: "https://github.com/virginie-sankara/bootcamp-final-project",
  },
  {
    id: "Critter",
    name: "Critter",
    className: "python",
    description:
      'is a project that involves creating a clone of Twitter for cats. The focus of this project is on the front-end development that allows users to view a "home feed" page, a single tweet page and a followers profile pages, liking a tweet and posting new tweets.',
    sub: "A clone of Twitter... for cats",
    technologies: ["React", "JSX", "HTML/CSS"],
    contributions: ["Front-end"],
    preview: require("./assets/images/CritterPreview.png"),
    previewAlt:
      "A screenshot of the profile page of the platform. At the left appears the logo which represents the silouhette of a purple cat and the sidebar menu. In the middle of the screenshot a cover image of an building is represented, with the profile picture of a cat named Gladstone, his informations and his tweets.",
  },
  {
    id: "Slingair",
    name: "Slingair",
    className: "cSharp",
    description:
      "is a seat-booking app that allows users to select their flight, choose a seat, enter their information, and receive a confirmation message, with the option to retrieve their reservation using localStorage.",
    sub: "A seat-booking app for jet-setters",
    technologies: ["React", "Node.js", "MongoDB", "Auth0", "JSX", "HTML/CSS"],
    contributions: ["Back-end"],
    preview: require("./assets/images/SlingairPreview1.png"),
    previewAlt:
      "A screenshot of the reservation page of the SlingShot Airlines platform. At the top center of the image appears the flight number : FD489. Under the Flight number appears the seating plan of the plane on which the user can click to select a seat. At the right appears a form to retrieve personal informations from the user to book his or her flight.",
  },
  {
    id: "SportsSavvy",
    name: "SportsSavvy",
    className: "java",
    description:
      "is a functional e-commerce team project website where users can come in and shop around for the tech they want.",
    sub: "An e-commerce platform for real sports savvies",
    technologies: ["React", "Node.js", "MongoDB", "Auth0", "JSX", "HTML/CSS"],
    contributions: ["Front-end", "UI", "UX"],
    preview: require("./assets/images/SportsSavvypreview.gif"),
    previewAlt:
      "A gif of the user experience of the platform on the search page for shopping connected watches.",
  },
  {
    id: "RickMortyCromulons",
    name: "Rick & Morty Escaping The Cromulons",
    className: "python",
    description:
      "is an Object Oriented Programming Game that centers around honing Object Oriented Programming skills through the implementation of a game inspired by Nyan Cats.",
    sub: "An Object Oriented Programming Game",
    technologies: ["Javascript", "HTML/CSS", "DOM"],
    contributions: ["Front-end", "UI", "UX"],
    preview: require("./assets/images/OopgPreview.gif"),
    previewAlt:
      "A gif of the game showcased where a spacial vessel with two passengers (Rick and Morty) tries to dodge enemies (big yellow meteors named Cromulons). At the right appears the title, the description and the score of the game",
  },
  {
    id: "GroupeDesTrente",
    name: "Groupe des Trente",
    className: "python",
    description:
      "is an engagement program whose mission is to inspire a diverse next generation in governance to enrich decision-making spaces. Created by Concertation Montréal in 2017, the initiative brings together multidisciplinary experts from underrepresented groups who are involved in boards of directors. Throughout their mandate, members are brought together to unite their skills and leadership to carry out actions that will encourage individuals in their networks to initiate themselves into governance. This CMTL program is supported by the Ministry of Immigration, Francization and Integration of Quebec, as part of the A Quebec that shines through its diversity initiative.",
    // sub: "An Object Oriented Programming Game",
    technologies: [
      "Wordpress",
      "MailChimp",
      "Adobe Creative Suite",
      "LinkedIn",
      "Facebook",
      "X",
    ],
    contributions: [
      "Project Management",
      "Communication Management",
      "Community Management",
    ],
    preview: require("./assets/images/312937982_485935996898657_265667181636748601_n.jpg"),
    previewAlt:
      "Virginie-Sankara Cantin-Diarra signe le Livre d'or de la Ville de Montréal. Credit Ville de Montréal - Sylvain Légaré",
    github_link:
      "https://concertationmtl.ca/groupe-des-trente/#:~:text=Le%20Groupe%20des%20Trente%20est,groupes%20sous%2Drepr%C3%A9sent%C3%A9s%20et%20impliqu%C3%A9.",
  },
  {
    id: "MakingYourWayUp",
    name: "Making Your Way Up: The Event",
    className: "python",
    description:
      "Organized by Sayaspora, Making Your Way Up is a free online summit focused on leadership and empowerment for Canadian-based African and Caribbean women of the diaspora.",
    // sub: "NA",
    // technologies: "",
    contributions: ["Panelist"],
    preview: require("./assets/images/4b0031eb-0b35-410a-a551-511cfd6940d4.png"),
    previewAlt: "Making Your Way Up Poster",
    github_link:
      "https://events.ringcentral.com/events/making-your-way-up-the-event-l-evenement/registration",
  },
];

module.exports = {
  tech_skills,
  workExperiences,
  academic_experiences,
  codingProjects,
};
