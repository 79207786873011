import React, { Component } from "react"; // Import Component from React
import Slider from "react-slick";
import styled from "styled-components";
import { tech_skills } from "./data";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesToShow: this.calculateSlidesToShow(window.innerWidth),
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  calculateSlidesToShow(width) {
    if (width >= 2000) {
      return 8;
    } else if (width >= 1600) {
      return 6;
    } else if (width >= 1200) {
      return 6;
    } else if (width >= 1024) {
      return 6;
    } else {
      return 4;
    }
  }

  handleResize() {
    this.setState({
      slidesToShow: this.calculateSlidesToShow(window.innerWidth),
    });
  }

  render() {
    const { slidesToShow } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      slidesToScroll: 1,
      autoplay: true,
      speed: 8000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      arrows: false,
      slidesToShow: slidesToShow,
    };

    return (
      <SliderContainer>
        <Slider {...settings}>
          {tech_skills.map((skill, i) => (
            <SkillCard key={i}>
              <SkillImage
                src={skill.imgSrc}
                alt={`Icon of ${skill.skillName}`}
              />
              <SkillName>{skill.skillName}</SkillName>
            </SkillCard>
          ))}
        </Slider>
      </SliderContainer>
    );
  }
}

const SliderContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: 8vh;
`;

const SkillCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SkillImage = styled.img`
  max-width: 80px;
  max-height: 80px;
  margin: auto;
  padding: 0;

  @media only screen and (max-width: 767px) {
    max-width: 70px;
    max-height: 70px;
  }

  @media only screen and (min-width: 1900px) {
    max-width: 100px;
    max-height: 100px;
  }
`;

const SkillName = styled.p`
  font-size: 14px;
  margin: auto;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  /* background: rgba(217, 217, 217, 0.1); */
  border-radius: 20px;
  padding: 6px;
  max-width: 140px;
  max-height: 50px;
`;
