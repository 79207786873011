import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { codingProjects } from "./data";
import styled from "styled-components";
import { FiArrowUpRight } from "react-icons/fi";

const ProjectDetails = () => {
  const { projectId } = useParams();

  // Scroll to the top of the page when the component mounts or 'page' state changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Find the project with the matching projectId
  const project = codingProjects.find((project) => project.id === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <PageWrapper>
      <PreviewDiv>
        <ProjectPreview src={project.preview} alt={project.previewAlt} />
      </PreviewDiv>
      <FlexRow>
        <Description>
          <h3>Description</h3>
          <p>
            <span>{project.name}</span>
            {project.description}
          </p>
        </Description>
        <FlexColumn>
          {project.technologies && (
            <>
              <h3>Technologies</h3>
              <p>{project.technologies.join(" • ")}</p>
            </>
          )}

          <h3>Contributions</h3>
          <p>{project.contributions.join(" • ")}</p>
        </FlexColumn>
      </FlexRow>
      {project.github_link && (
        <button
          className="btn"
          onClick={() => window.open(project.github_link, "_blank")}
          // rel="noopener noreferrer"
        >
          <span>
            Link <ArrowIcon>{FiArrowUpRight}</ArrowIcon>
          </span>
        </button>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding: 8vh 18vw 2vh 18vw;

  @media only screen and (max-width: 480px) {
    padding: 70px 6vw 2vh 6vw;
  }

  .btn {
    margin-top: 4vh;
    margin-bottom: 4vh;
    width: 100%;
    height: 8vh;

    span {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  p {
    font-size: 14px;
  }
`;

const PreviewDiv = styled.div`
  max-height: 60vh;
`;

const ProjectPreview = styled.img`
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
  border-radius: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 480px) {
    max-width: 844.82px;
    height: auto;
    object-fit: cover;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;

  @media only screen and (max-width: 1440px) {
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
`;

const FlexColumn = styled.div`
  flex-direction: column;
  font-size: 18px;
  line-height: 4vh;
  margin-left: 2vw;
  width: 100%;
  /* background-color: antiquewhite; */
  text-align: right;

  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 1440px) {
    text-align: left;
    margin: auto;
  }
`;

const Description = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 4vh;

  @media only screen and (max-width: 480px) {
    font-size: 14px;
    width: 100%;
  }

  span {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin-right: 4px;

    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const ArrowIcon = styled(FiArrowUpRight)`
  color: white;
  animation: none;
  display: flex;
  position: static;
  margin-left: 0.5vw;
`;

export default ProjectDetails;
