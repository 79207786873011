import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Projects.css";
import ProjectsMobile from "./ProjectsMobile";
import BingeBuddyCover from "./assets/images/bingebuddycover.jpeg";
import CritterCover from "./assets/images/crittercover.jpeg";
import SlingairCover from "./assets/images/slingaircover.jpeg";
import SportsSavvyCover from "./assets/images/sportssavvycover.jpeg";
import OOG from "./assets/images/oogcover.jpeg";
import G30 from "./assets/images/312937982_485935996898657_265667181636748601_n.jpg";
import MYWU from "./assets/images/4b0031eb-0b35-410a-a551-511cfd6940d4.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logoproto from "./assets/images/vscodesportologowhite.png";
import CoverPhoto from "./assets/images/walling-OvLXbURo9Wo-unsplash.jpg";

const Projects = () => {
  const [activeOption, setActiveOption] = useState(0); // Initialize the active option
  const [hoveredOption, setHoveredOption] = useState(0); // Initialize hovered option

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = size.width < 1600;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const options = [
    {
      id: "BingeBuddy",
      backgroundUrl: BingeBuddyCover,
      icon: "fad fa-camera-movie",
      main: "BingeBuddy",
      sub: "A playful and collaborative movie and TV show selector",
      altD: "Photography of a hand holding a tv remote in front of a TV presenting an app called BingeBuddy",
      type: "Full Stack Development - UI/UX",
    },
    {
      id: "Critter",
      backgroundUrl: CritterCover,
      icon: "fad fa-paw",
      main: "Critter",
      sub: "A clone of Twitter... for cats",
      alt: "Photography of a brown kitten trying to catch a bird represented by the Twitter Logo. There is a blue background behind the kitten and the name of the platform Critter in front of the kitten.",
      type: "Front End Development",
    },
    {
      id: "Slingair",
      backgroundUrl: SlingairCover,
      icon: "fad fa-plane",
      main: "Slingair",
      sub: "A seat-booking app for jet-setters",
      alt: "Photography of a a blue sky with pink clouds and the right wing of a plane. On the center of the photography the name of the platform : Slingair is represented in blue and white capital letters.",
      type: "Back End Development + UI",
    },
    {
      id: "SportsSavvy",
      backgroundUrl: SportsSavvyCover,
      icon: "fad fa-watch-fitness",
      main: "SportSavvy",
      sub: "An e-commerce platform for real sports savvies",
      alt: "Close-up photograph of a racetrack with an orange background and white lines. An iPad is placed on the track displaying an e-commerce platform for connected sports accessories named SportsSavvy",
      type: "Front End Development + UI",
    },
    {
      id: "RickMortyCromulons",
      backgroundUrl: OOG,
      icon: "fad fa-game-console-handheld",
      main: "Rick & Morty & the Cromulons",
      sub: "An Object Oriented Programming Game",
      alt: "Image of a 2D spaceship drawn in space. Inside the spaceship, the characters Rick and Morty from the eponymous series appear. Above the spaceship, the title of the application : Rick and Morty Escaping the Cromulons is displayed in blue and white letters.",
      type: "Object Oriented Development",
    },
    {
      id: "GroupeDesTrente",
      backgroundUrl: G30,
      main: "Groupe des Trente",
      alt: "Virginie-Sankara Cantin-Diarra signe le Livre d'or de la Ville de Montréal. Credit Ville de Montréal - Sylvain Légaré",
      type: "Project Management + Community Management",
    },
    {
      id: "MakingYourWayUp",
      backgroundUrl: MYWU,
      main: "Making Your Way Up",
      alt: "Making Your Way Up Poster",
      type: "Panel",
    },
  ];

  const handleOptionClick = (index) => {
    setActiveOption(index);
  };

  const handleOver = (index) => {
    setHoveredOption(index);
  };

  const handleOut = () => {
    setHoveredOption(null);
  };

  // Scroll to the top of the page when the component mounts or 'page' state changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWrapper>
      <NavLink
        to="/"
        role="navigation"
        aria-label="Navigate to Home Page"
      ></NavLink>
      {!isMobile && (
        <>
          <CoverDiv>
            <Cover src={CoverPhoto} />
          </CoverDiv>
          <Title>work</Title>
          <RowDiv>
            <LeftDiv>
              {/* <div>
                <NameLeft>WORK</NameLeft>
              </div> */}
              <ImageDiv>
                <Link to={`/work/${options[hoveredOption || activeOption].id}`}>
                  <ProjectOverview
                    // src={
                    //   hoveredOption !== null
                    //     ? options[hoveredOption].backgroundUrl
                    //     : options[activeOption].backgroundUrl
                    // }
                    // alt={options[activeOption].alt}
                    src={
                      hoveredOption !== null
                        ? options[hoveredOption].backgroundUrl
                        : "none"
                    }
                    alt={options[activeOption].alt}
                  />
                </Link>
              </ImageDiv>
            </LeftDiv>
            <ProjectsList>
              {options.map((option, index) => (
                <LinkStyled to={`/work/${option.id}`} key={index}>
                  <ProjectDes
                    key={index}
                    onMouseEnter={() => handleOver(index)}
                    // onMouseLeave={handleOut}
                  >
                    <ProjectName>{option.main}</ProjectName>
                    <ProjectType>{option.type}</ProjectType>
                    <HRGradient></HRGradient>
                  </ProjectDes>
                </LinkStyled>
              ))}
            </ProjectsList>
          </RowDiv>
        </>
      )}

      {isMobile && <ProjectsMobile />}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background: rgba(0, 0, 0, 0.4);
  background: rgba(13, 15, 17, 0.5);
  /* height: 100vh; */
  /* padding-top: 30px; */

  @media only screen and (max-width: 767px) {
    /* padding-top: 10px; */
  }
`;

// const TitleDiv = styled.div`
//   border-radius: 40px;
//   width: 100%;
//   display: flex;
//   @media only screen and (max-width: 767px) {
//     padding: 10px;
//     margin: 0;
//   }
// `;

const Title = styled.h1`
  color: rgb(246, 255, 249);
  font-family: Poppins;
  font-size: 180px;
  font-style: normal;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  left: 6vw;
  top: 40vh;
  position: absolute;
  align-items: center;
`;

const CoverDiv = styled.div`
  width: 100vw;
  height: 90vh;
  background: rgba(0, 0, 0, 1);
  margin-bottom: 0;
`;

const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  /* filter: blur(2px); */
`;

const RowDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  /* margin-left: -100px; */
  margin-top: 130px;
  padding-right: 100px;
`;

const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  /* position: fixed; */
`;

const ImageDiv = styled.div`
  /* width: 600px;
  height: 400px; */
`;

const ProjectOverview = styled.img`
  width: 45vw;
  height: 45vh;
  /* background-color: aliceblue; */
  border-radius: 50px;
  border: transparent;
  /* position: fixed; */
  padding: 0;
  object-fit: cover;
  right: 100px;
  /* margin-top: 220px; */

  transition: transform 0.3s ease; /* Add transition effect */

  &:hover {
    transform: scale(1.1); /* Scale up by 10% on hover */
  }
`;

const NameLeft = styled.h2`
  font-size: 60px;
  margin: 0 0 40px 0;
  font-weight: 300;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  font-family: Poppins;
  font-style: normal;
  padding: 0;

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

const ProjectsList = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: aliceblue; */
  /* margin-left: 700px; */
  margin: auto;
  text-transform: uppercase;
  text-decoration: none;
  width: 40%;
  :hover {
    text-decoration: none;
  }
`;

const ProjectName = styled.h3`
  text-decoration: none;
  font-weight: 200;
  font-style: italic;
  font-size: 40px;
  margin: 0 0 10px 0;
  :hover {
    text-decoration: none;
  }
`;

const ProjectType = styled.p`
  margin: 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: 200;
  :hover {
    text-decoration: none;
  }
`;

const ProjectDes = styled.div`
  margin: 0;
  text-decoration: none;
`;

const HRGradient = styled.div`
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
  height: 2px;
  margin: 20px 0;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;

  :hover {
    text-decoration: none;
  }
`;

export default Projects;
