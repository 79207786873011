import styled from "styled-components";
import { academic_experiences } from "./data";

const Education = () => {
  return (
    <PageWrapper>
      <SectionDiv>
        <BoxDiv>
          {/* <SectionTitle>education</SectionTitle> */}
          {academic_experiences.map((experience, i) => (
            <>
              <EducationEntry key={i}>
                <Logo src={experience.logo} alt={experience.logoAlt} />
                <EducationTextDiv>
                  <Degree>{experience.degree}</Degree>
                  <Institution>{experience.institution}</Institution>

                  {experience.degree && (
                    <BottomDiv>
                      <Description>{experience.description}</Description>
                    </BottomDiv>
                  )}
                </EducationTextDiv>
              </EducationEntry>
              {/* <Separator /> */}
            </>
          ))}
        </BoxDiv>
      </SectionDiv>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  /* height: 100vh; */
`;

const SectionDiv = styled.div`
  /* border-radius: 40px; */
  /* margin: auto auto 1vh auto; */
  width: 100%;
  max-width: 1000px;
  /* height: 100vh; */
  /* background: rgba(217, 217, 217, 0.05); */

  @media only screen and (max-width: 480px) {
    padding: 20px;
  }
`;

const BoxDiv = styled.div`
  border-radius: 40px;
  /* padding: 40px; */
  margin: auto auto 1vh auto;
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
`;

const SectionTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  margin-top: 0;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const TopDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
`;

const BottomDiv = styled.div``;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  @media only screen and (max-width: 480px) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    /* border: 1px solid blue; */
  }
`;

const EducationEntry = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  background: rgba(217, 217, 217, 0.05);
  padding: 20px;
  border-radius: 30px;

  /* border: yellow solid 2px; */
  @media only screen and (max-width: 480px) {
  }
`;

const Degree = styled.h4`
  font-size: 16px;
  margin: 0 0 1vh 0;
  font-weight: 500;

  @media only screen and (max-width: 480px) {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 2px;
    line-height: 2.5vh;
  }
`;

const Institution = styled.p`
  font-size: 14px;
  margin: 0;
  @media only screen and (max-width: 480px) {
    font-size: 13px;
    margin-top: 0.5vh;
    line-height: 2.5vh;
  }
`;

const EducationTextDiv = styled.div`
  margin-left: 40px;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin-left: 4vw;
  }
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 1.5;

  @media only screen and (max-width: 480px) {
    /* font-size: 12px; */
    display: none;
  }
`;

const Separator = styled.div`
  height: 0.5px;
  background: linear-gradient(
    to right,
    #b63f95 9.6%,
    #ec6a42,
    #f2b858,
    #92f8dd 93.6%
  );
`;

export default Education;
