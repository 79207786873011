import styled from "styled-components";
import React, { useState, useEffect } from "react";
import factrymobile from "./assets/images/factrymobile.jpeg";
import factryDesktop from "./assets/images/factryDesktop.png";
import LogoFta from "./assets/images/LogoFta.jpg";
import LogoVA from "./assets/images/LogoVA.jpeg";
import LogoBME from "./assets/images/LogoBME.png";
import photographyBg from "./assets/images/photographyBg.jpg";

const Snippets = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = size.width < 768;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDivClick = (event) => {
    const linkType = event.currentTarget.dataset.link;

    switch (linkType) {
      case "link1":
        window.open("https://fta.ca/en/", "_blank");
        break;
      case "link2":
        window.open("https://vuesdafrique.org/", "_blank");
        break;
      case "link3":
        window.open("https://www.bemyeyes.com/about", "_blank");
        break;
      case "link4":
        window.open(
          "https://concertationmtl.ca/cmtl-et-la-presse-batissent-la-diversite-dans-les-medias/",
          "_blank"
        );
        break;
      case "link5":
        window.open(
          "https://www.factry.ca/blogue/le-groupe-des-trente/",
          "_blank"
        );
        break;
      case "link6":
        window.open(
          "https://www.facebook.com/RCVAgirAutrement/videos/153927016732754",
          "_blank"
        );
        break;
      case "link7":
        window.open("https://unsplash.com/fr/@virginiesankara", "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <PageWrapper>
      {/* <SectionDiv>
        <SectionTitle>snippets</SectionTitle>
      </SectionDiv> */}
      <SectionDiv>
        <SectionTitle>volunteering</SectionTitle>
        <br></br>
        <OrgContainer onClick={handleDivClick} data-link="link1">
          <div>
            <Logo src={LogoFta} />
          </div>
          <OrgTextDiv>
            <OrgName>Festival TransAmériques</OrgName>
            <OrgP>Member of the board of directors</OrgP>
          </OrgTextDiv>
        </OrgContainer>
        <OrgContainer onClick={handleDivClick} data-link="link2">
          <div>
            <Logo src={LogoVA} />
          </div>
          <OrgTextDiv>
            <OrgName>Festival Vues d'Afrique</OrgName>
            <OrgP>Jury member 2023</OrgP>
          </OrgTextDiv>
        </OrgContainer>
        <OrgContainer onClick={handleDivClick} data-link="link3">
          <div>
            <Logo src={LogoBME} />
          </div>
          <OrgTextDiv>
            <OrgName>Be My Eyes</OrgName>
            <OrgP>Virtual volunteer</OrgP>
          </OrgTextDiv>
        </OrgContainer>
      </SectionDiv>
      <SectionDiv>
        <SectionTitle>media</SectionTitle>
      </SectionDiv>
      <VideoDiv>
        <ResponsiveVideo
          src="https://www.youtube.com/embed/gtuWJUdMYx4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </VideoDiv>
      <SectionDiv onClick={handleDivClick} data-link="link4">
        <MediaTitle>
          Improving the representation of diversity in the media: CMTL and La
          Presse collaborate
        </MediaTitle>
        <P>
          Video I had the opportunity to co-direct, produced by CMTL in
          partnership with La Presse on the issue of the representativeness of
          diversity experts in the media. The video, which lasts just over six
          minutes, features La Presse's management and leading figures from the
          Groupe des Trente, a CMTL initiative whose mission is to inspire a
          diverse succession in governance to enrich decision-making.
        </P>
      </SectionDiv>
      {!isMobile && (
        <FactryImg
          src={factryDesktop}
          onClick={handleDivClick}
          data-link="link5"
        />
      )}
      {isMobile && (
        <FactryImg
          src={factrymobile}
          onClick={handleDivClick}
          data-link="link5"
        />
      )}
      <SectionDiv onClick={handleDivClick} data-link="link5">
        <MediaTitle>
          {" "}
          <i>Le Groupe des Trente</i> : For the common good
        </MediaTitle>
        <P>
          "Connecting elected officials with socio-economic leaders to serve as
          an incubator for civic participation: this is the ambitious project of
          CMTL, which has chosen to focus its energies where Montreal society
          needs them most: with women, young people and people from diverse
          backgrounds. Its actions, which are becoming increasingly widespread
          throughout Quebec, are aimed at achieving greater representation of
          these groups on decision-making bodies such as boards of directors
          (BoDs)."
        </P>
      </SectionDiv>
      <VideoDiv>
        <ResponsiveVideo
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FRCVAgirAutrement%2Fvideos%2F153927016732754%2F&show_text=false&appId=1324008914350647"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          frameborder="0"
          allowfullscreen="true"
        />
      </VideoDiv>
      <SectionDiv onClick={handleDivClick} data-link="link6">
        <MediaTitle>Interview with Radio Centre-Ville 102.3FM</MediaTitle>
      </SectionDiv>

      <PhotoDiv onClick={handleDivClick} data-link="link7">
        <PhotoTitle>photography</PhotoTitle>
      </PhotoDiv>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const SectionDiv = styled.div`
  border-radius: 40px;
  background: rgba(217, 217, 217, 0.05);
  padding: 40px;
  margin: auto auto 1vh auto;
  width: 100%;
  cursor: pointer;
`;

const SectionTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  margin: 0 auto auto auto;
  cursor: default;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
    /* text-align: center; */
  }
`;

const ResponsiveVideo = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 40px;

  // @media only screen and (max-width: 480px) {
  //   width: 89vw;
  //   height: 28.5vh;
  //   border-radius: 40px;
  // }
`;

const VideoDiv = styled.div`
  position: relative;
  height: 400px;

  ${ResponsiveVideo} {
    position: absolute;
    inset: 0;
    height: 100%;
  }

  @media (max-width: 767px) {
    height: 200px;
  }
`;

const MediaTitle = styled.h4`
  line-height: 25px;
  font-size: 18px;
  font-weight: 600;
`;

const P = styled.p`
  line-height: 30px;
`;

const FactryImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  @media only screen and (max-width: 480px) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    /* border: 1px solid blue; */
  }
`;

const OrgContainer = styled.div`
  display: flex;
  gap: 40px;
  padding-top: 20px;
  margin-bottom: 2vh;

  @media only screen and (max-width: 480px) {
    gap: 15px;
  }
`;

const OrgName = styled.h4`
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  margin-bottom: 1vh;
  @media only screen and (max-width: 480px) {
    font-size: 13px;
    margin-top: 0;
  }
`;

const OrgP = styled.p`
  font-size: 16px;
  margin: 0;
  @media only screen and (max-width: 480px) {
    font-size: 13px;
    line-height: 2.5vh;
  }
`;

const OrgTextDiv = styled.div`
  margin: auto 0 auto 0;
  /* border: pink solid 2px; */

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const PhotoDiv = styled.div`
  border-radius: 40px;
  background: url(${photographyBg}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  cursor: pointer;
`;

const PhotoTitle = styled.h4`
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin: auto;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

export default Snippets;
