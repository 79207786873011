import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import holdingSmartphone from "./assets/images/holdingSmartphone.png";
import sendIcon from "./assets/images/sendIcon.png";
import longArmSmallRes from "./assets/images/longArmSmallRes.png";
import { NavLink } from "react-router-dom";
import logoproto from "./assets/images/vscodesportologowhite.png";

const mkAnimation = (to) => keyframes`
to {
  left: ${to}vw;
}
`;

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, subject, message }),
      });

      const data = await response.json();
      console.log(data.message); // Server response message
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Scroll to the top of the page when the component mounts or 'page' state changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWrapper>
      <NavLink
        to="/"
        role="navigation"
        aria-label="Navigate to Home Page"
      ></NavLink>
      <BlobContainer>
        <div className="shape-blob"></div>
        <div className="shape-blob one"></div>
        <div className="shape-blob two"></div>
      </BlobContainer>
      <Section1>
        <CTA>READY, SET, CONNECT</CTA>
        <Text>
          If you're up for game-changing collabs, top-notch consulting, or
          exciting job prospects...
        </Text>
        {/* <HandImg src={holdingSmartphone} /> */}
        <SlideText>slide into my DMs ↴</SlideText>
        <HandImg
          src={longArmSmallRes}
          alt="Representation of an arm holding a phone"
        />
      </Section1>

      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <FormLabel>
            {/* <p class="visuallyhidden">name</p> */}
            <Input
              type="text"
              placeholder="Name"
              value={name}
              required={true}
              onChange={handleNameChange}
              autoComplete="name"
              for="name"
              aria-label="name"
            />
          </FormLabel>
          <br />
          <FormLabel>
            {/* <p class="visuallyhidden">email</p> */}
            <Input
              type="email"
              placeholder="Email"
              required={true}
              value={email}
              onChange={handleEmailChange}
              autoComplete="email"
              for="email"
              aria-label="Email"
            />
          </FormLabel>
          <br />
          <FormLabel>
            {/* <p class="visuallyhidden">subject</p> */}
            <Input
              type="text"
              placeholder="Subject"
              required={true}
              value={subject}
              onChange={handleSubjectChange}
              for="subject"
              aria-label="subject"
            />
          </FormLabel>
          <br />
          <FormLabel>
            {/* <p class="visuallyhidden">message</p> */}
            <TextArea
              value={message}
              placeholder="Message"
              required={true}
              maxLength={500}
              onChange={handleMessageChange}
              for="message"
              aria-label="message"
            />
          </FormLabel>
          <br />
          <button type="submit" class="btn" aria-label="submit">
            <span>
              {" "}
              <SendImg src={sendIcon} alt="submit icon" />{" "}
            </span>
          </button>
        </Form>
      </FormWrapper>
    </PageWrapper>
  );
};

const BlobContainer = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: -1;
`;

const PageWrapper = styled.div`
  padding: 40px 60px;
  /* margin: auto; */

  @media screen and (min-width: 2000px) {
    max-width: 2000px;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 20px;
  }

  .btn {
    width: 80px;
    height: 80px;
  }

  .btn span {
    border-radius: 50%;
    background: rgba(0, 0, 0, 1);
  }

  .shape-blob {
    background: linear-gradient(#b63f95, #ec6a42, #92f8dd);
    height: 600px;
    width: 600px;
    border-radius: 30% 50% 20% 40%;
    animation: border-radius 20s ease-in-out infinite both alternate,
      movement_two 40s ease-in-out infinite both;
    opacity: 0.9;
    z-index: -1;
    filter: blur(3rem);
    position: absolute;
    left: 20%;
    top: 50%;
    will-change: transform;
  }

  .shape-blob.one {
    height: 500px;
    width: 500px;
    left: -100px;
    top: -50px;
    animation: border-radius 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;
  }

  .shape-blob.two {
    height: 1000px;
    width: 1000px;
    left: 500px;
    top: 150px;
    animation: border-radius 30s ease-in-out infinite both alternate,
      movement_two 60s ease-in-out infinite both;

    @media only screen and (max-width: 767px) {
      height: 500px;
      width: 500px;
    }

    @media only screen and (min-width: 2000px) {
      height: 1500px;
      width: 1500px;
      left: 500;
      top: 20%;
    }
  }

  @keyframes border-radius {
    0%,
    100% {
      border-radius: 33% 67% 70% 30% / 30% 30% 70% 70%;
    }
    20% {
      border-radius: 37% 63% 51% 49% / 37% 65% 35% 63%;
    }
    40% {
      border-radius: 36% 64% 64% 36% / 64% 48% 52% 36%;
    }
    60% {
      border-radius: 37% 63% 51% 49% / 30% 30% 70% 70%;
    }
    80% {
      border-radius: 40% 60% 42% 58% / 41% 51% 49% 59%;
    }
  }

  @keyframes movement_two {
    0%,
    500% {
      transform: none;
    }
    50% {
      transform: translate(50%, 20%) rotate(-200deg) scale(1.5);
    }
  }
`;

const Section1 = styled.div`
  border-radius: 44px;
  background: rgba(0, 0, 0, 0.85);
  height: calc(100vh - 15vh);
  height: calc(100dvh - 15dvh);
  position: relative;
  padding: 40px;
  z-index: 2;
  margin-bottom: 24px;
  margin-top: 50px;

  @media only screen and (max-width: 767px) {
    background: rgba(0, 0, 0, 0.8);
    height: calc(100vh - 12vh);
    height: calc(100dvh - 12dvh);
    margin-top: 20px;
  }
`;

const CTA = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 3;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 2000px) {
    font-size: 40px;
  }
`;

const Text = styled.h2`
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: normal;
  z-index: 3;
  /* width: 1000px; */

  @media only screen and (max-width: 1200px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 2000px) {
    line-height: 10vh;
    font-size: 74px;
  }
`;

const HandImg = styled.img`
  width: 25vw;
  height: auto;
  animation-name: ${mkAnimation(-10)};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: absolute;
  z-index: 3;
  top: 63vh;
  left: -100%;
  pointer-events: none;

  /* @media only screen and (min-width: 2000px) {
    width: 1100px;
    animation-name: ${mkAnimation(-18)};
    top: 45%;
  }

  @media only screen and (max-width: 1200px) {
    width: 500px;
    animation-name: ${mkAnimation(-17)};
  }

  @media only screen and (max-width: 767px) {
    width: 350px;
    animation-name: ${mkAnimation(-10)};
  }

   */
  @media only screen and (max-width: 767px) {
    width: 85vw;
    animation-name: ${mkAnimation(-35)};
    top: 47vh;
  }
`;

const SlideText = styled.h2`
  flex-shrink: 0;
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 40px;
  right: 50px;
  margin: 0;
  z-index: 3;

  @media only screen and (max-width: 767px) {
    width: 198px;
    font-size: 24px;
  }

  @media only screen and (min-width: 2000px) {
    font-size: 74px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 2000px) {
    font-size: 20px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormLabel = styled.label`
  border-radius: 44px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  z-index: 1;
  /* TO DO : find out why no margin bottom in firefox */
  margin-bottom: 1vh;

  &:focus-within {
    box-shadow: 0 0 0 1px #92f8dd;
  }

  ::placeholder {
    color: white;
    opacity: 1;
  }

  @media only screen and (max-width: 767px) {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const Input = styled.input`
  background: none;
  padding: 30px;
  border: none;
  color: white;
  width: 100%;

  &:focus {
    outline: 0;
  }
`;

const TextArea = styled.textarea`
  background: transparent;
  padding: 30px;
  border: none;
  margin: auto;
  color: white;
  height: 15vh;
  width: 100%;

  &:focus {
    outline: 0;
  }
`;

const SendImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

const LogoDiv = styled.div`
  top: -10px;
  left: 7vw;
  z-index: 2;
  position: relative;

  /* extraWide viewport */
  @media only screen and (min-width: 1640px) {
    margin: auto 120px;
    margin-top: 58px;
  }

  @media only screen and (max-width: 767px) {
    top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    left: 0;
    width: 100%;
  }
`;

const Logo = styled.a`
  font-family: Italiana;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 12px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.07);

  &:hover {
    text-decoration: none;
    color: black;
    color: white;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
    color: white;
    /* position: relative; */
  }
`;

const LogoIcon = styled.img`
  width: 40px;
  height: auto;
  /* position: relative; */

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
`;

export default Contact;
